import { Modal } from 'bootstrap';
import SignaturePad from 'signature_pad';

export default class Signature {
    constructor() {}

    initialize(idModal) {
        console.log('signature.initialize(' + idModal + ')');
        let self = this;

        self.modal = new Modal(document.getElementById(idModal), {
            backdrop: 'static',
            keyboard: false
        });
        self.canvas = document.querySelector('#' + idModal + ' canvas');
    }

    cancel() {
        console.log('signature.cancel()');
        let self = this;

        try {
            self.SignaturePad.off();
            self.modal.hide();
        } catch (error) {
            console.error(error);
        }
    }

    sign(cbOK) {
        console.log('signature.sign(<cbOK>)');
        let self = this;

        self.SignaturePad = new SignaturePad(self.canvas);

        var buttonOK = document.querySelector('#signatureModalOK');
        if (cbOK) {
            buttonOK.onclick = function() {
                // Reset the onclick itself so it won't happen next time
                document.getElementById('infoModalOK').removeAttribute('onclick');
                // Close the popup
                //                document.getElementById('infoModal')._modal.hide();
                //                self.popupInfoClose();
                // Do what needs to be done, if there is anything
                if (cbOK) { cbOK() }
            }
            buttonOK.classList.remove('hidden');
        } else {
            buttonOK.classList.add('hidden');
        }

        self.modal.show();
    }

    getData() {
        console.log('signature.getData()');
        let self = this;
        return self.SignaturePad.toData();
    }

    drawFromData(canvas, data) {
        console.log('signature.drawFromData(<canvas>,<data>)');
        var signPad = new SignaturePad(canvas);
        signPad.fromData(data);
    }
}