<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark sticky-top bg-dark">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/" replace>{{ name }}</router-link>
        <button v-if="!isLogin" class="navbar-toggler" @click="toggleMenu()" type="button" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-if="!isLogin" class="navbar-collapse collapse" id="navbarCollapse">
          <ul class="navbar-nav ms-auto mb-2 mb-md-0">
            <li class="nav-item"><router-link class="nav-link active" to="/sync" replace>Synchroniseren</router-link></li>
            <li class="nav-item"><router-link class="nav-link active" to="/debug" replace>Support</router-link></li>
            <li class="nav-item"><a class="nav-link active" @click="doLogout()">Uitloggen</a></li>
          </ul>
        </div>
      </div>
    </nav>

    <div v-if="isMobile() || !isMobile()">
      <div id="container_vue" class="container">
        <router-view />
      </div>
      <div id="container_app" class="container p-3 mb-4" hidden>
        The app-forms are loaded here.
      </div>
    </div>

    <div v-else class="warning text-center mt-5">
      <p>
        Deze applicatie werkt alleen op een mobiel device.
      </p>
      <p>
        Ga naar {{server}} op je pad of mobiel,<br>
        of scan deze QR-code:
      </p>
      <p>
        <img :src="qrSource" :alt="server" />
      </p>
      <p>
        <small class="tiny">Heb je geen QR-code scanner op je mobiel?<br>Gebruik dan https://seeqr.eu</small>
      </p>
    </div>

    <div class="modal fade" id="infoModal" tabindex="-1" aria-labelledby="infoModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="infoModalLabel">...</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body overflow-auto" style="max-height:350px">
            <p class="description" id="infoModalDescription">...</p>
          </div>
          <div class="modal-footer">
            <button type="button" id="infoModalCancel" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
            <button type="button" id="infoModalOK" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="signatureModal" tabindex="-1" aria-labelledby="signtureModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="signtureModalLabel">Handtekening</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onclick="application.signature.cancel()"></button>
          </div>
          <div class="modal-body text-center">
            <canvas></canvas>
          </div>
          <div class="modal-footer">
            <button type="button" id="signatureModalCancel" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
            <button type="button" id="signatureModalOK" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="scanQRModal" tabindex="-1" aria-labelledby="scanQRModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="scanQRModalLabel">QR-code</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onclick="application.qr.cancel()"></button>
          </div>
          <div class="modal-body overflow-auto text-center" style="min-height:300px; max-height:700px">
            <video id="qr-video"></video>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="cameraModal" tabindex="-1" aria-labelledby="cameraModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body overflow-auto text-center px-0 mx-0" style="min-height:300px; max-height:700px">
            <button type="button" class="btn btn-camflash" id="cameraModalFlash" aria-label="Flash" style="position:absolute;top:1.2rem;left:2rem"><i class="fas fa-bolt"></i></button>
            <button type="button" class="btn btn-camswitch" id="cameraModalSwitch" aria-label="Switch" style="position:absolute;top:1.2rem;left:4.5rem" hidden><i class="fas fa-redo"></i></button>
            <button type="button" class="btn-close btn-camclose" onclick="application.camera.cancel()" aria-label="Close" style="position:absolute;top:1.5rem;right:2rem"></button>
            <button type="button" class="btn-camshot" id="cameraModalShoot" aria-label="Shoot"></button>
            <canvas id="camera-canvas" class="p-0 m-0" style="max-width:90%; max-height:80%">
              <video id="camera-video" class="p-0 m-0"></video>
            </canvas>
          </div>
        </div>
      </div>
    </div>

    <footer class="fixed-bottom bg-dark text-center text-light small py-1" v-if="isMobile()">
      {{name}} - <span class="small">{{version}}</span><span v-if="!app.api.isOnline" class="small ps-2 blink"><i class="fas fa-wifi" style="color:red"></i></span>
    </footer>
  </div>
</template>

<style>
/* https://stackoverflow.com/questions/36212722/how-to-prevent-pull-down-to-refresh-of-mobile-chrome */
html, body {
  overscroll-behavior-y: contain;
}

/* h3s are used for form titles; the padding makes sure we break before the close button */
h3 {
	padding-right: 30px;
}
tr td:not(:last-child) {
    padding-right: 10px;
}
td {
	vertical-align: top;
}

/* Nested fieldsets */
fieldset fieldset fieldset {
  padding-left: 24px;
  padding-top: 6px;
}

/* Buttons with icons should have some spacing (unless they consist ONLY of an icon) */
button.no-icon-space i.fa, a.no-icon-space i.fa, button.no-icon-space svg, a.no-icon-space svg {
  margin-right: 0;
}
button i.fa, a i.fa, button svg, a svg {
  margin-right: 0.5em;
}

/* Don't show any of the templates */
[data-lb-class="template"] {
	display: none;
}

/* This class is used by function logboek.show()/hide()/toggle() */
.hidden {
  display: none;
}
.warning {
  color: #bb0000;
  font-weight: bold;
}
.tiny {
  font-size: 80%;
}
.form-label {
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: #777;
  font-weight: 500;
}
.nodecoration {
  text-decoration: none;
  color: inherit;
}
.missing {
  color: red;
}
.text-black-25 {
  /* Mostly used for the uploaded cloud icon */
  opacity: 0.25;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.btn-camswitch,
.btn-camflash {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  filter: drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white) drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white);
}
.btn-camflash[data-flash=true] {
  opacity: 1;
  color: white;
  filter: drop-shadow(2px 0 0 gray) drop-shadow(0 2px 0 gray) drop-shadow(-2px 0 0 gray) drop-shadow(0 -2px 0 gray)
}
.btn-camclose {
  filter: drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white) drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white);
}
.btn-camshot {
  position: absolute;
  bottom: 1.5rem;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid #aaa;
  opacity: 0.5;
  background-color: #ddd;
  filter: drop-shadow(0px 0px 2px white);
}

label {
  white-space: pre-wrap;
}
#container_image {
  cursor: crosshair;
}

/* tomSelect for selects */
.ts-wrapper .option p {
	display: block;
  padding: 0;
  margin: 0;
}
.ts-wrapper .option p:nth-child(n+2) {
	font-size: 12px;
	display: block;
	color: #a0a0a0;
}

#container_app {
  min-height: 100vh;
}
#container_app[data-lb-mode="add"],
#container_app[data-lb-mode="edit"] {
  background-color: #f0f0ff;
}
#container_app[data-lb-mode="read"] {
  background-color: white;
}

/* Simple hide-when rules */
#container_app[data-lb-mode="read"] .hide-on-read,
#container_app[data-lb-mode="edit"] .hide-on-edit,
#container_app[data-lb-mode="add"] .hide-on-add {
  display: none;
}

#container_app.readonly fieldset .ts-wrapper * {
  /* This disables mouse interactions with tom-select elements */
  pointer-events: none;
}
#container_app.readonly fieldset .ts-wrapper {
  background-color: #e9ecef;
}
#container_app.readonly fieldset .btn,
#container_app.readonly fieldset .btn-check {
  opacity: 0.3 !important;
  filter: saturate(0);
}
/* Position the clear button (x) to the left of the dropdown caret */
.plugin-clear_button.focus.has-items .clear-button, .plugin-clear_button:hover.has-items .clear-button {
  margin-right: 2em !important;
}

/* When presenting the specified values, honor the white spacing and cr/lfs */
#infoModalDescription.text {
  white-space: pre-wrap;
}
#infoModalDescription td {
  width: 50%;
}
#signatureModal canvas {
  border: 1px solid gray;
}
#qr-video {
  width: 80vw;
}

/* Pictures that were taken shouldn't be too large when displayed */
#pictures .picture {
	width: 30%;
	margin: 0 0.5em 0.5em 0;
	border: 1px solid gray;
}

/* Blinking */
@keyframes blinker {
  from { opacity: 1; }
  to { opacity: 0; }
}
.blink {
  animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}
</style>

<script>
import { Modal } from 'bootstrap';
import { useRoute } from 'vue-router';

export default {
  data() {
    return {
      server: this.app.server(),
      name: this.app.name,
      version: this.app.version,
      user: this.app.user,
    }
  },

  computed: {
    isLogin() {
      const route = useRoute();
      return (route.path == '/login' );
    },

    qrSource() {
      return 'https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=' +this.server;
    }
  },

  methods: {
    toggleMenu() {
      try {
        let el = document.getElementById('navbarCollapse');
        if (el) {
          el.classList.toggle('show');
        }
      } catch (error) {
        console.error(error);
      }
    },

    doLogout() {
      this.app.logout();
      location.reload(true);
      //this.$router.replace('/login');
    },

    isMobile() {
      return this.app.isMobile();
    }
  },

  mounted() {
    console.log('app.mounted');

    // Initialize some functions
    this.app.signature.initialize('signatureModal');
    this.app.qr.initialize('scanQRModal');
    this.app.camera.initialize(this.app.storage, 'cameraModal','cameraModalShoot','cameraModalSwitch');
    this.app.attachment.initialize(this.app.storage);

    // Add the bootstrap reference to the element in the DOM
    let modal = document.getElementById('infoModal');
    modal._modal = new Modal(modal, {
      backdrop: true,
      keyboard: true,
      focus: true
    });

    // When the info modal is closed, certain properties are reset (position, opacity of the backdrop, visibility of buttons, onclick of buttons)
    modal.addEventListener('hidden.bs.modal', () => {
      let modal = document.getElementById('infoModal');
      modal.style.top = '0px';
      //let backdrop = document.querySelector('.modal-backdrop.show');
      //backdrop.style.opacity = '0.05';
      let btns = document.querySelectorAll('#infoModal button');
      for( let btn of btns ) {
        btn.hidden = false;
        // Remove any onclick function - just removing the attribute didn't seem to work, so we set it to null first
        btn.onclick = null;
        btn.removeAttribute('onclick');
      }
    });
  }
};
</script>