<template>
  <div class="home mt-2 mb-5">
     <div class="container">
      <div class="py-2 text-center">
        Hallo {{app.user.username}},<br>
        <span class="tiny">Je bent verbonden met {{app.api.server}}.</span>
      </div>
    </div>

    <div class="container">
      <div v-if="info" class="py-2">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link px-2 active" id="nav-information-tab" data-bs-toggle="tab" data-bs-target="#nav-information" type="button" role="tab" aria-controls="nav-information" aria-selected="true">Lopende projecten</button>
            <button class="nav-link px-2" id="nav-projects-tab" data-bs-toggle="tab" data-bs-target="#nav-projects" type="button" role="tab" aria-controls="nav-projects" aria-selected="false">Gearchiveerd</button>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">

          <div class="tab-pane fade py-4 show active" id="nav-information" role="tabpanel" aria-labelledby="nav-information-tab">
            <div v-for="p in projects_assigned" :key="p" class="card mt-2 mb-1">
              <div class="card-body" @click="doLoadProject(p.id)">
                <h6 class="card-title my-0">{{p.data.projectnummer}}</h6>
                <p class="card-text">{{p.data.projectnaam}}</p>
              </div>
            </div>
          </div>

          <div class="tab-pane fade py-3" id="nav-projects" role="tabpanel" aria-labelledby="nav-projects-tab">
            <div v-for="p in projects_archived" :key="p" class="card mt-2 mb-1">
              <div class="card-body" @click="doLoadProject(p.id)">
                <h6 class="card-title my-0">{{p.data.projectnummer}}</h6>
                <p class="card-text">{{p.data.projectnaam}}</p>
              </div>
            </div>
            <p class="small tiny text-center mt-2">
              Neem contact op met de applicatiebeheerder als een project ontbreekt.
            </p>
          </div>
        </div>
      </div>
      <div v-else class="mt-3 text-center">
        De applicatie moet eerst gesynchroniseerd worden.<br>
        <router-link class="btn btn-primary btn-sm my-3 py-2 px-3" to="/sync" replace>Synchroniseren</router-link>
      </div>

    </div>

  </div>
</template>

<style scoped>
td {
  padding-left: 0;
}
/* Hide the button when a accordion item is expanded */
#accordionInfo .accordion-button:not(.collapsed)::after {
  display: none;
}
</style>

<script>
export default {
  name: 'Home',

  data() {
    return {
      // When the database updates, it will transmit an event that gets picked up; it will use this forceRedraw to force a -wait for it- redraw
      forceRedraw: 0,
    }
  },

  computed: {
    info() {
      let forms_info = this.app.storage.get('info.forms_info');
      return forms_info;
    }
  },

  methods: {
    doLoadForm(key) {
      console.log('doLoadForm(' +key +')');
      this.app.loadForm(key);
    },

    doLoadProject(id) {
      console.log('doLoadProject(' +id +')');
      delete this.app.project_active_item;
      let record = this.app.logboek.getProject(id);
      this.app.loadForm('project',record,'read');
    },

    doShowDetails(row) {
      console.log('doShowDetails');

      // Get the form info for this record so we know what to show and where to go
      let forms = this.app.storage.get('info.forms_info');
      let form = null;
      for (let cnt in forms) {
        if (forms[cnt].key == row.type) {
          form = forms[cnt];
        }
      }

      // Calculate an overview over the values
      let innerHTML = '';
      for (let column in form.columns) {
        innerHTML += this.app.escapeHTML(column) + ': <span class="' + form.columns[column] + '">' + this.app.escapeHTML(row.data[form.columns[column]]) + '</span><br>';
      }

      // Give the ok button something to do
      document.getElementById('infoModalOK').onclick = () => {
          this.app.loadForm(row.type, row);
      };

      // Show the modal with the buttons, with a very light backdrop, the modal towards the bottom of the screen and only one cancel button
      document.getElementById('infoModalLabel').innerText = form.title;
      document.getElementById('infoModalDescription').innerHTML = innerHTML;
      document.getElementById('infoModal')._modal.show();
    },

    data(key) {
      let result = this.app.storage.getAll('created.' +key +'.');
      result.sort( (a,b) => {
        return (a.createdAt > b.createdAt ? -1 : 1)
      });
      return result;
    }
  },

  beforeMount() {
    this.projects_archived = [];
    this.projects_assigned = [];

    var projects = this.app.logboek.getProjects();
    for( var cnt in projects ) {
      //projects[cnt].data = JSON.parse(projects[cnt].data);
      if( projects[cnt].archived === null ) {
        this.projects_assigned.push( projects[cnt] );
      } else {
        this.projects_archived.push( projects[cnt] );
      }
    }

    // Sort based on projectnummer
    this.projects_assigned.sort((a,b) => (a.data.projectnummer > b.data.projectnummer) ? 1 : -1 );
    this.projects_archived.sort((a,b) => (a.data.projectnummer > b.data.projectnummer) ? 1 : -1 );

    let app = this.app.storage.get('info.app');
    if( app && app.css ) {
      let element = document.getElementById('app_css');
      if( !element ) {
        element = document.createElement('style');
        element.id = 'app_css';
      }
      element.setAttribute('type', 'text/css');
      if ('textContent' in element) {
        element.textContent = app.css;
      } else {
        element.styleSheet.cssText = app.css;
      }
      document.getElementsByTagName('head')[0].appendChild(element);
    }
  },

  mounted() {
    // When the database is changed, it emits an event; react to it to redraw the information
    window.addEventListener('storage-changed', (data) => {
      console.log( 'storage-changed(' +data.detail.action +')' );
      this.forceRedraw++;
    });

    // Make sure the first tab is visible (sometimes that gets wrong)
    let infoTab = document.getElementById('nav-information-tab');
    if( infoTab ) {
      infoTab.click();
    }
  }
}
</script>