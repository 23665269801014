import { Modal } from 'bootstrap';

export default class Camera {
    constructor() {}

    initialize(storage, idModal, idBtnShoot, idBtnCamSwitch = null, idBtnFlashToggle = null) {
        console.log('camera.initialize(<storage>,' + idModal + ',' + idBtnShoot + ',' + idBtnCamSwitch + ',' + idBtnFlashToggle + ')');
        let self = this;

        self.storage = storage;
        self.scanning = false;
        self.canvas = document.querySelector('#' + idModal + ' canvas');
        self.video = document.querySelector('#' + idModal + ' canvas video');
        self.modal = new Modal(document.getElementById(idModal), {
            backdrop: 'static',
            keyboard: false
        });
        self.elBtnShoot = document.getElementById(idBtnShoot);
        self.elBtnCamSwitch = document.getElementById(idBtnCamSwitch);
        self.elBtnFlashToggle = document.getElementById(idBtnFlashToggle);

        // Activate the flash toggle
        if (self.elBtnFlashToggle) {
            self.elBtnFlashToggle.onclick = () => { self.toggleFlash() };
        }

        // Make the list of all devices available so we can change cameras
        // Ideally we'd only use "environment" cameras, but the result isn't consistent across browsers yet (2022-04-28)
        self.deviceCurrent = self.storage.get('camera.deviceLastUsed');
        self.devices = [];
        navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
            for (let deviceInfo of deviceInfos) {
                if (deviceInfo.kind === 'videoinput') {
                    self.devices.push(deviceInfo);
                }
            }
        });
    }

    cancel() {
        console.log('camera.cancel()');
        let self = this;

        try {
            self.scanning = false;
            self.video.srcObject.getTracks().forEach((track) => {
                track.stop();
            });
            self.modal.hide();
        } catch (error) {
            console.error(error);
        }
        return true;
    }

    toggleFlash() {
        console.log('camera.toggleFlash()');
        let self = this;

        let flashStatus = !(self.elBtnFlashToggle.dataset.flash == 'true');
        self.elBtnFlashToggle.dataset.flash = flashStatus;

        const track = self.video.srcObject.getVideoTracks()[0];
        console.log(flashStatus);
        track.applyConstraints({
            advanced: [{ torch: flashStatus }]
        }).catch(error => {
            console.error(error);
        });
    }

    switchCamera(uuid, callback) {
        console.log('camera.switchCamera(' + uuid + ')');
        let self = this;

        // Find the current device if set, so we can select the next, if required
        let currentPos;
        try {
            // Find the position in the list of devices
            currentPos = self.devices.map(obj => obj.deviceId).indexOf(self.deviceCurrent);
            if (currentPos == -1) {
                // The last used camera was not found in the list of cameras, set it to the first camera in the list
                currentPos = 0;
            }

            // Switch to the next camera (if available, and we're currently showing a camera)
            if (document.getElementById('cameraModal').className.includes('show')) {
                if (currentPos < self.devices.length - 1) {
                    currentPos++;
                } else {
                    currentPos = 0;
                }
            }

            // Stop everything we've been doing, reset the flash icon
            if (self.video.srcObject) {
                self.video.srcObject.getTracks().forEach((track) => {
                    track.stop();
                    if (self.elBtnFlashToggle) {
                        self.elBtnFlashToggle.dataset.flash = false;
                    }
                });
            }
        } catch (error) {
            console.error(error);
            currentPos = 0;
        }
        self.deviceCurrent = self.storage.set('camera.deviceLastUsed', self.devices[currentPos].deviceId);

        // Make the cam-switch button visible, if more cams are available
        if (self.devices.length > 1) {
            if (self.elBtnCamSwitch) {
                self.elBtnCamSwitch.hidden = false;
            }
        }

        let constraints = {
            video: true,
            facingMode: { ideal: "environment" },
            aspectRatio: { ideal: 16 / 9 },
            width: { min: 800, ideal: 1200 }, // This is the width of the image, not the canvas
        };
        if (self.deviceCurrent) {
            constraints.video = {
                deviceId: { exact: self.deviceCurrent }
            };
        }

        // https: //stackoverflow.com/questions/49126616/mediadevices-getusermedia-image-cropped-zoomed-in-chrome
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                self.modal.show();

                self.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
                self.video.srcObject = stream;
                self.video.play();

                self.scanning = true;
                self.tick();

                // When clicking the button assigned to react to taking the picture, run the shoot, with the callback
                // Install this only now, after that the stream has started and the popup is showing
                if (self.elBtnShoot) {
                    // https://stackoverflow.com/a/50020566/4177565
                    self.elBtnShoot.onclick = () => { self.shoot(uuid, callback) };
                }
                if (self.elBtnCamSwitch) {
                    self.elBtnCamSwitch.onclick = () => { self.switchCamera(uuid, callback) };
                }
            })
            .catch((error) => {
                console.error(error);
                document.querySelector('#cameraModal .modal-body').innerText = 'Kamera er ikke tilgjengelig for øyeblikket.\nVennligst juster innstillingene og gi appen tilgang.'
            });
    }

    capturePhoto(uuid, callback) {
        console.log('camera.capturePhoto(' + uuid + ')');
        let self = this;
        self.switchCamera(uuid, callback);
    }

    tick() {
        console.log('camera.tick()');
        try {
            this.canvas.height = this.video.videoHeight;
            this.canvas.width = this.video.videoWidth;
            this.canvas
                .getContext("2d")
                .drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
        } catch (error) {
            console.error(error);
            // Ignore
        }
        if (this.scanning) {
            setTimeout(() => {
                this.tick()
            }, 150);
        }
    }

    shoot(uuid, callback) {
        console.log('camera.shoot(' + uuid + ')');
        let self = this;

        try {
            if (self.scanning) {
                this.video.pause();
            }

            // Store the image from the canvas into the storage as blob
            let key = 'photos.' + uuid;
            let canvas = document.getElementById("camera-canvas");
            self.storage.saveCanvasAsBlob(key, canvas).then(() => {
                // Update the list of images in the localstorage (for direct access)
                self.storage.setIn('app.pictures', key, {
                    key: key,
                    createdAt: window.application.GMTToLocal(),
                    status: 'local'
                });

                // Do the callback, if specified
                if (callback) {
                    callback(key);
                }
            });

            self.scanning = false;
            self.video.srcObject.getTracks().forEach((track) => {
                track.stop();
            });
            self.modal.hide();
        } catch (error) {
            console.error(error);
        }
    }

    resetPictureList() {
        // Rebuilds the internal list of pictures in localstorage, "app.pictures"
        console.log('camera.resetPictureList()');
        let self = this;

        let currentList = self.storage.get('app.pictures') || {};
        self.storage.blobs().then((blobs) => {
            let result = {};
            for (let blob of blobs) {
                if (blob.substring(0, 7) == 'photos.') {
                    let createdAt = currentList[blob].createdAt || window.application.GMTToLocal();
                    result[blob] = {
                        key: blob,
                        createdAt: createdAt,
                        status: 'local'
                    };
                }
            }
            self.storage.set('app.pictures', result);
        });
        return true;
    }
}