export default class Attachment {
    constructor() {}

    initialize(storage) {
        console.log('attachment.initialize(<storage>)');
        let self = this;

        self.storage = storage;
    }

    add(element, uuid) {
        console.log('attachment.add(<element,' + uuid + '>)');
        let self = this;

        return new Promise(function(resolve, reject) {
            if (element && element.files) {
                var fileObj = document.querySelector('#attachment_select').files[0];
                if (fileObj) {
                    // Store the attachment into the storage as blob
                    let storageKey = 'attachments.' + uuid + '.' + fileObj.name;
                    self.storage.saveFileAsBlob(storageKey, fileObj).then((storageKey) => {
                        // Reset the element so the user can attach another
                        element.value = '';

                        // Update the list of attachments in the localstorage (for direct access)
                        self.storage.setIn('app.attachments', storageKey, {
                            key: storageKey,
                            createdAt: window.application.GMTToLocal(),
                            status: 'local'
                        });

                        resolve(storageKey);
                    });
                } else {
                    reject('No file was selected.');
                }
            } else {
                reject('The element is not a file input.');
            }
        });
    }

    remove(storageKey) {
        console.log('attachment.remove(' + storageKey + ')');
    }

    resetAttachmentList() {
        // Rebuilds the internal list of attachments in localstorage, "app.attachments"
        console.log('attachment.resetAttachmentList()');
        let self = this;

        let currentList = self.storage.get('app.attachments') || {};
        self.storage.blobs().then((blobs) => {
            let result = {};
            for (let blob of blobs) {
                if (blob.substring(0, 12) == 'attachments.') {
                    let createdAt = currentList[blob].createdAt || window.application.GMTToLocal();
                    result[blob] = {
                        key: blob,
                        createdAt: createdAt,
                        status: 'local'
                    };
                }
            }
            self.storage.set('app.attachments', result);
        });
        return true;
    }
}