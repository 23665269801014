export default class API {
    constructor(storage) {
        this.storage = storage;
    }

    parseTemplate(html = '', data = [], opening = '{', closing = '}') {
        // Replaces fields encapsulated with <opening> and <closing> with values from the objects in array data
        // console.log('logboek.parseTemplate(<html>,<data>,' + opening + ',' + closing + ')');
        try {
            for (let fieldset of data) {
                for (let field in fieldset) {
                    if (fieldset[field] == null) {
                        fieldset[field] = 'n/a';
                    }
                    let regex = new RegExp('\\' + opening + field + '\\' + closing, 'g');
                    html = html.replace(regex, fieldset[field]);
                }
            }

            // Replace unused placeholders with a '-'
            let regex = new RegExp('\\' + opening + '.*?' + '\\' + closing, 'gi');
            return html.replace(regex, '-');
        } catch (error) {
            console.error(error);
            return html;
        }
    }

    show(el) {
        el.classList.remove('hidden');
    }

    hide(el) {
        el.classList.add('hidden');
    }

    toggle(el) {
        if (el.classList.contains('hidden')) {
            el.classList.remove('hidden');
        } else {
            el.classList.add('hidden');
        }
    }

    doShowHide(el) {
        // Runs code to automatically show/hide related elements based on checkbox/radio choices made
        console.log('logboek.doShowHide(<el>)');
        let self = this;

        // This function reacts to changes to elements that are child inputs of container elements with data-lb-showhide="true"
        // Such an element (el) can have the following data properties:
        // 'data-lb-toggle': toggles display of the listed elements
        // 'data-lb-show': shows the listed elements
        // 'data-lb-hide': hides the listed elements
        // 'data-lb-showautohide': shows the listed elements, unless no checkboxes/radios with this id in data-lb-showautohide is selected, in which case we hide the element

        // We do the following in case two or more checkboxes will trigger showing the same element (todo: check)
        var el_hlp = document.querySelector('textarea:active');
        if (el_hlp) el_hlp.blur();
        el.focus();

        try {
            if (el.dataset.lbShow) document.querySelectorAll(el.dataset.lbShow).forEach(el_target => self.show(el_target));
            if (el.dataset.lbHide) document.querySelectorAll(el.dataset.lbHide).forEach(el_target => self.hide(el_target));
            if (el.dataset.lbToggle) document.querySelectorAll(el.dataset.lbToggle).forEach(el_target => self.toggle(el_target));

            if (el.dataset.lbShowautohide) document.querySelectorAll(el.dataset.lbShowautohide).forEach(el_target => {
                if (el.checked) {
                    self.toggle(el_target);
                } else {
                    self.toggle(el_target);
                }
            });
        } catch (e) {
            // Ignore
        }
    }

    installRepeatingItems(listElement, options) {
        // This functions installs the listeners for the edit/add/delete buttons
        console.log('logboek.installRepeatingItems(<listElement>,<options>)');
        let self = this;

        // Pickup settings from the element
        let formElement = document.querySelector(listElement.dataset.lbForm);
        let showElement = document.querySelector(listElement.dataset.lbShow);

        // Show the list of items
        self.showRepeatingItems(listElement.value, showElement);

        // Install listeners for the remove, edit and add buttons (which are in the showElement)
        showElement.addEventListener('click', e => {
            let itemId = e.target.closest('tr').dataset.lbRownumber;

            if (e.target.closest('.lb-delete')) {
                console.log('logboek.repeatingItems.delete(' + itemId + ')');
                self.deleteRepeatingItem(
                    () => {
                        // Remove the item
                        let listItems = JSON.parse(listElement.value);
                        delete listItems[itemId];
                        listItems = listItems.filter((item) => Object.keys(item).length);

                        // Update the element, then redraw the list of items
                        listElement.value = JSON.stringify(listItems);
                        self.showRepeatingItems(listElement.value, showElement);
                    }
                );
            } else if (e.target.closest('.lb-edit') || e.target.closest('.lb-add')) {
                var item = {}
                if (itemId) {
                    console.log('logboek.repeatingItems.edit(' + itemId + ')');
                    let listItems = JSON.parse(listElement.value);
                    item = listItems[itemId];
                } else {
                    console.log('logboek.repeatingItems.add()');
                }

                self.popupRepeatingItem(
                    item,
                    itemId,
                    formElement.innerHTML,
                    options,
                    () => {
                        // Create the new item from the popup form
                        var formElement = document.querySelector('#logboek_popup');
                        let item = Object.fromEntries(new FormData(formElement));

                        var listItems = [];
                        try {
                            listItems = JSON.parse(listElement.value);
                        } catch (error) {
                            // Ignore
                        }
                        if (typeof itemId == 'undefined') {
                            itemId = listItems.length;
                        }
                        listItems[itemId] = item;

                        // Update the element, then redraw the list of items
                        listElement.value = JSON.stringify(listItems);
                        self.showRepeatingItems(listElement.value, showElement);
                    }
                );
            }
        });
    }

    showRepeatingItems(items, el) {
        // This function shows the list items (usually list_dagstaat), as rows in the tbody of <el>
        console.log('logboek.showRepeatingItems(<items>,<el>)');
        try {
            try {
                items = JSON.parse(items);
            } catch (error) {
                items = [];
            }

            var container = el.querySelector('tbody');
            var template = el.querySelector('[data-lb-class="template"]');

            container.innerHTML = '';
            if (container && template) {
                items.forEach((listItem, rowId) => {
                    let html = template.outerHTML.replace('data-lb-class="template"', '');
                    for (var item in listItem) {
                        html = html.replaceAll('{' + item + '}', listItem[item]);
                    }
                    html = html.replaceAll('{rownumber}', rowId);
                    container.insertAdjacentHTML('beforeend', html);
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    popupRepeatingItem(listItem, listItemKey, htmlTemplate = '', options = {}, cbOk = null) {
        console.log('logboek.popupRepeatingItem(<listItem>,<html>,<options>,<cbOk>)');
        try {
            // Fill in the values for the selects
            for (let key in options) {
                var html2add = options[key].map(e => '<option>' + e.value + '</option>').join('');
                if (listItem) {
                    if (Object.prototype.hasOwnProperty.call(listItem, key)) {
                        html2add = html2add.replaceAll('<option>' + listItem[key] + '</option>', '<option selected>' + listItem[key] + '</option>');
                    }
                }
                htmlTemplate = htmlTemplate.replace('{' + key + '}', html2add);
            }

            // Fill in the values for the inputs
            if (listItem) {
                for (let key in listItem) {
                    htmlTemplate = htmlTemplate.replaceAll('{' + key + '}', listItem[key]);
                }
            }

            // Remove any other placeholders, make it a form
            let regex = new RegExp('\\{.*?\\}', 'gi');
            htmlTemplate = htmlTemplate.replace(regex, '');
            htmlTemplate = '<form id="logboek_popup">' + htmlTemplate + '</form>';

            // Show the info modal
            window.application.confirm(htmlTemplate, (Object.keys(listItem).length === 0 ? 'Invoer' : 'Wijzigen'), () => {
                if (cbOk) {
                    cbOk(listItemKey);
                }
            }, null, true);
        } catch (error) {
            console.error(error);
            window.application.alert('Er is een fout opgetreden en het onderdeel kon niet worden getoond.');
        }
    }

    deleteRepeatingItem(cbOk) {
        console.log('logboek.deleteRepeatingItem(<cbOk>)');
        window.application.confirm('Dit element verwijderen?', 'Verwijderen?', cbOk);
    }

    copyRepeatingItemsFrom(record_id, fk_project, contentType, fieldName, showTitle, showLabel, listElement) {
        console.log('logboek.copyRepeatingItemsFrom(' + record_id + ',' + fk_project + ',' + contentType + ',' + fieldName + ',' + showTitle + ',' + showLabel + ',<listElement>)');
        let self = this;

        let contents = self.getProjectContents(fk_project, [contentType]);
        if (contents && contents[contentType]) {
            contents = contents[contentType]
                .filter(e => e.id != record_id)
                .sort((a, b) => (a.data[fieldName] < b.data[fieldName]) ? 1 : -1);
        }

        let cnt = 0;
        let html = '<label class="form-label">' + showLabel + '</label>';
        contents.forEach(e => {
            html += '<div class="form-check">' +
                '<input class="form-check-input" type="radio" name="key" id="key_' + cnt + '" value="' + e.id + '">' +
                '<label for="key_' + (cnt++) + '">' + e.data[fieldName] + '</label>' +
                '</div>';
        });

        window.application.confirm(html, showTitle, () => {
            var id = document.querySelector('#infoModal [name=key]:checked').value;
            var contents = window.application.storage.get('info.projects_contents.' + fk_project + '.' + id);

            // Copy the list, then redraw it
            let listItems = contents.data[listElement.name];
            //listElement.value = JSON.stringify(listItems);
            console.log(listItems);
            listElement.value = JSON.stringify(listItems);
            self.showRepeatingItems(listElement.value, document.querySelector(listElement.dataset.lbShow));
        }, null, true);
    }

    getProjects() {
        console.log('logboek.getProjects()');
        let self = this;
        return self.storage.getAll('info.project.');
    }

    getProject(id) {
        console.log('logboek.getProject(' + id + ')');
        let self = this;
        return self.storage.get('info.project.' + id);
    }

    getProjectContents(id, types = null) {
        console.log('logboek.getProjectContents(' + id + ')');
        let self = this;

        let results = [];
        let contents = self.storage.getAll('info.projects_contents.' + id + '.');
        for (var content of contents) {
            if (types === null || types.includes(content.type)) {
                if (!results[content.type]) {
                    results[content.type] = [];
                }
                results[content.type].push(content);
            }
        }
        return results;
    }
}