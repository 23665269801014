import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [{
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: function() {
            return import ( /* webpackChunkName: "share" */ '../views/Login.vue')
        }
    },
    {
        path: '/sync',
        name: 'sync',
        component: function() {
            return import ( /* webpackChunkName: "create" */ '../views/Sync.vue')
        }
    },
    {
        path: '/debug',
        name: 'debug',
        component: function() {
            return import ( /* webpackChunkName: "share" */ '../views/Debug.vue')
        }
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default {
    install(vue) {
        router.install(vue);

        router.beforeEach((to, from, next) => {
            try {
                console.log('router.beforeEach(/' + from.name + ' -> /' + to.name + ')');

                // Collapse the navbar (some bootstrap issue causes it to not collapse)
                let el = document.getElementById('navbarCollapse');
                if (el) {
                    el.classList.remove('show');
                }

                // Unload any HTML form before loading a vue component
                let app = vue.config.globalProperties.app;
                let container = document.getElementById('container_app');

                if (container && !container.hidden) {
                    // If we're looking at a form, don't do anything - the user must first exit the form
                    next(false);
                    return;
                }

                if (to.path == '/login') {
                    // If we're going to /login, continue
                    next();
                } else if (!app || !app.user || !app.user.token) {
                    // User did not login yet, so go to /login
                    next({ path: '/login' });
                } else {
                    // Proceed as requested
                    next();
                }
            } catch (error) {
                console.error(error);
                next({ path: '/login' });
            }
        });
    }
}