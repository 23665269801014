export default class Console {
    constructor() {}

    initialize(postLog = false, postError = false, postURL = '') {
        // Define
        window.app_console = {
            log: [],
            error: []
        };

        // Hook into the console: https://stackoverflow.com/a/9278067/4177565
        (function() {
            var exLog = console.log;
            console.log = function(msg) {
                try {
                    exLog.apply(this, arguments);

                    // Only add if it's unlike the previous, otherwise add a counter to the previous one
                    let message = (typeof msg == 'object' ? msg.constructor.name + ' obj' : msg);
                    if (window.app_console.log.length) {
                        let tmp = window.app_console.log[window.app_console.log.length - 1].split(' - ');
                        if (tmp[0] == message) {
                            tmp[1] = parseInt('0' + tmp[1]) + 1;
                            window.app_console.log[window.app_console.log.length - 1] = tmp[0] + ' - ' + tmp[1];
                            message = '';
                        }
                    }

                    // Only do something if the message is unlike the previous one
                    if (message != '') {
                        window.app_console.log.push(message);

                        // Max 1000 entries
                        if (window.app_console.log.length > 1000) {
                            window.app_console.log = window.app_console.log.slice(1);
                        }
                        // Optional POST
                        if (postLog && postURL != '' && window.application.isOnline()) {
                            let formData = new FormData();
                            formData.append('function', 'log');
                            formData.append('msg', msg);
                            fetch(postURL, {
                                method: 'POST',
                                body: formData,
                                headers: {
                                    'Authorization': 'Bearer ' + window.application.api.token
                                }
                            }).catch(error => {
                                console.warn(error);
                            });
                        }
                    }
                } catch (error) {
                    console.warn(error);
                }
            }
        })();

        (function() {
            var exLog = console.error;
            console.error = function(msg) {
                try {
                    exLog.apply(this, arguments);
                    window.app_console.error.push((typeof msg == 'object' ? msg.constructor.name + ' obj' : msg));
                    // Max 1000 entries
                    if (window.app_console.error.length > 1000) {
                        window.app_console.error = window.app_console.error.slice(1);
                    }
                    // Optional POST
                    if (postError && postURL != '' && window.application.isOnline()) {
                        let formData = new FormData();
                        formData.append('function', 'error');
                        formData.append('msg', msg);
                        fetch(postURL, {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'Authorization': 'Bearer ' + window.application.api.token
                            }
                        }).catch(error => {
                            console.warn(error);
                        });
                    }
                } catch (error) {
                    console.warn(error);
                }
            }
        })();
    }
}