const md5 = require("md5");
const sha1 = require("sha1");

export default class Application {
    constructor(args) {
        try {
            // Copy everything given to the constructor; storage and api are expected
            // Storage will be sent along so the API can properly initialize
            for (let prop in args) {
                this[prop] = args[prop];
            }

            // Get stuff from config
            this.name = config.VUE_CONFIG_APP_NAME;
            this.version = 'v' + process.env.VUE_APP_VERSION;

            // Get stuff from the local storage
            this.properties = ['user', 'tables.users'];
            for (let prop of this.properties) {
                this[prop] = this.storage.get(prop);
            }

            // These are the timer used to control the timeout before the upload function is called again
            this.uploadTimerFast = 200;
            this.uploadTimerSlow = 4000;

            // When loading a form to read a record, initForm will pickup the values from this global
            // If null, the form is for editing; if 
            this.record = null;
        } catch (error) {
            console.error(error);
        }
    }

    initialize() {
        console.log('app.initialize()');
        let self = this;

        // Initialization of the API may take some time as it may want to login at the server, so make it a promise
        return new Promise(function(resolve, reject) {
            try {
                self.api.initialize(self.storage);

                // Initialize the console (don't post logs, do post errors)
                if (self.console) {
                    if (window.location.host != 'localhost:8080' && self.api.server) {
                        self.console.initialize(false, true, 'https://' + self.api.server + self.api.server_path + 'console');
                    }
                }

                // Start the uploader
                //self.upload();

                resolve();
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    server() {
        return (window.location.href + '#').split('#')[0]
    }

    isMobile() {
        return (/iPhone|iPad|iPod|Android|Opera\sMini|Windows\sPhone/i.test(navigator.userAgent));
    }

    isOnline() {
        let self = this;
        return self.api.isOnline;
    }

    isStandalone() {
        // https://stackoverflow.com/a/51735941/4177565
        return (window.matchMedia('(display-mode: standalone)').matches);
    }

    GMTToLocal(GMTTime = null) {
        var date = GMTTime ? new Date(GMTTime) : new Date();
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 19).replace('T', ' ');
    }

    getGPS(callback) {
        console.log('app.getGPS(<callback>)');

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    callback(position);
                },
                (error) => {
                    console.error(error);
                    callback(null);
                }, {
                    maximumAge: 0,
                    timeout: 5000,
                    enableHighAccuracy: true
                }
            );
        } else {
            callback(null);
        }
    }

    generateUUID() {
        // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
        if (crypto) {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        } else {
            let d = new Date().getTime();
            let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = Math.random() * 16;
                if (d > 0) { // Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else { // Use microseconds since page-load (if supported)
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }
    }

    escapeHTML(html) {
        return String(html).replaceAll('<', '&lt;').replaceAll('>', '&gt;');
    }

    parseNumber(value) {
        // Parse value as float, but replace the european comma with a dot first
        if (value.trim() == '') {
            return 0;
        }
        return parseFloat(value.trim().replaceAll(' ', '').replaceAll(', ', '.'));
    }

    alert(message, title = '', isHTML = false) {
        console.log('app.alert(' + message + ',' + title + ',' + isHTML + ')');
        let self = this;

        // Load the title and text
        document.getElementById('infoModalLabel').innerText = title || self.name;
        var el = document.getElementById('infoModalDescription');
        if (isHTML) {
            el.classList.remove('text');
            el.innerHTML = message;
        } else {
            el.classList.add('text');
            el.innerText = message;
        }

        // Hide the Cancel button, prepare the OK button
        document.getElementById('infoModalCancel').hidden = true;
        document.getElementById('infoModalOK').hidden = false;
        document.getElementById('infoModalOK').removeAttribute('onclick');

        // Show the modal with the overview
        document.getElementById('infoModal')._modal.show();
    }

    confirm(message, title = '', cbOK = null, cbCancel = null, isHTML = false) {
        console.log('app.confirm(' + message + ',' + title + ',<cbOK>,<cbCancel>,' + isHTML + ')');
        let self = this;

        // Load the title and text
        document.getElementById('infoModalLabel').innerText = title || self.name;
        var el = document.getElementById('infoModalDescription');
        if (isHTML) {
            el.classList.remove('text');
            el.innerHTML = message;
        } else {
            el.classList.add('text');
            el.innerText = message;
        }

        // Show the Cancel and OK buttons, prepare them 
        document.getElementById('infoModalCancel').hidden = false;
        document.getElementById('infoModalCancel').onclick = function() {
            // Reset the onclick itself so it won't happen next time
            document.getElementById('infoModalCancel').removeAttribute('onclick');
            // Close the popup
            self.popupInfoClose();
            // Do what needs to be done, if there is anything
            if (cbCancel) { cbCancel() }
        }
        document.getElementById('infoModalOK').hidden = false;
        document.getElementById('infoModalOK').onclick = function() {
            // Reset the onclick itself so it won't happen next time
            document.getElementById('infoModalOK').removeAttribute('onclick');
            // Close the popup
            self.popupInfoClose();
            // Do what needs to be done, if there is anything
            if (cbOK) { cbOK() }
        }

        // Show the modal with the overview
        document.getElementById('infoModal')._modal.show();
    }

    md5(s) {
        return md5(s);
    }
    sha1(s) {
        return sha1(s);
    }

    login(server = '', username = '', password = '', forceServer = false) {
        console.log('app.login(' + server + ',' + username + ',<password>,' + forceServer + ')');
        let self = this;

        if (self.storage.has('info.user') && !forceServer && !self.isOnline()) {
            return new Promise(function(resolve, reject) {
                console.log('Logging in locally');
                let users = self.storage.get('info.user');
                let password_hash = sha1(md5(password));
                for (let u in users) {
                    if (users[u].username == username && users[u].password == password_hash) {
                        // The token is set to something not empty, so this will allow the user to use the app locally
                        // But before uploading, the user will have to authenticate
                        self.user = {
                            'id': users[u].id,
                            'token': 'local-login',
                            'username': users[u].username,
                        };
                        self.storage.set('user', self.user);
                        resolve(true);
                    }
                }
                reject();
            });
        } else {
            return new Promise(function(resolve, reject) {
                console.log('Logging in remotely');
                self.api.login(server, username, password)
                    .then(result => {
                        self.user = self.storage.set('user', result);
                        resolve(true);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }

    logout() {
        console.log('app.logout()');
        let self = this;

        try {
            // Remove the token and store the new user object
            delete self.user.token;
            self.user = self.storage.set('user', self.user);
            return true;
        } catch (error) {
            console.error(error);
            return true;
        }
    }

    executeScripts(containerElement) {
        // This is used by loadForm
        // https://stackoverflow.com/a/69190644/4177565
        let self = this;
        try {
            const scriptElements = containerElement.querySelectorAll("script");

            Array.from(scriptElements).forEach((scriptElement) => {
                const clonedElement = document.createElement("script");

                Array.from(scriptElement.attributes).forEach((attribute) => {
                    clonedElement.setAttribute(attribute.name, attribute.value);
                });

                clonedElement.text = scriptElement.text;

                scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
            });
        } catch (error) {
            console.error(error);
            self.alert('An error occurred when loading the form; please contact application support.');
        }
    }

    loadForm(name, record = null, mode = 'read') {
        console.log('app.loadForm(' + name + ',<record>,' + mode + ')');
        let self = this;

        // Close the info modal
        self.popupInfoClose();

        // Set the record to be picked up by initForm
        // We're settings this as a global because this app does not control how the forms deal with this
        // We expect the form to call loadInfo at some point, where we will pickup this again
        self.record = record;

        // Hide the vue container
        document.getElementById('container_vue').hidden = true;

        // Figure out the html to load
        let html = self.storage.get('forms.' + name + '.html');
        if (html) {
            if (record.id) {
                // A record exists, so output the record-id
                html = '<fieldset data-lb-record-id="' + record.id + '">' + html + '</fieldset>';
            } else {
                // No record exists yet
                html = '<fieldset>' + html + '</fieldset>';
            }
        } else {
            // The close button will not ask confirmation before unloading the form
            html = 'Dit formulier ontbreekt nog. De applicatie moet eerst gesynchroniseerd worden.';
        }
        let el = document.getElementById('container_app');
        el.dataset.lbMode = mode;
        el.innerHTML = '<button type="button" class="btn-close float-end" aria-label="Close" style="position:absolute;top:4.5rem;right:1rem" onclick="application.unloadForm(' + (record ? 'true' : '') + ')"></button>' + html;
        window.scrollTo(0, 0);

        self.executeScripts(el);
        el.hidden = false;
    }

    initForm(definitions = {}) {
        // Loads the options for selects
        console.log('app.initForm(<definitions>)');

        return new Promise(function(resolve, reject) {
            try {
                // Set the tom-select selects
                let tomSelectDefault = {
                    maxItems: 1,
                    valueField: 'id',
                    labelField: 'value',
                    selectOnTab: true,
                    render: {
                        option: function(data, escape) {
                            let result = '';
                            for (let key in data) {
                                if (key.substring(0, 1) != '$' && key != 'id') {
                                    result += `<p class="${key}">` + escape(data[key]) + '</p>';
                                }
                            }
                            return '<div>' + result + '</div>';
                        },
                        item: function(data, escape) {
                            return '<div title="' + escape(data.id) + '">' + escape(data.value) + '</div>';
                        },
                        option_create: function(data, escape) {
                            return '<div class="create"><strong>' + escape(data.input) + '</strong> toevoegen&hellip;</div>';
                        },
                        no_results: function() {
                            return '<div class="no-results">Niets gevonden.</div>';
                        },
                    }
                };

                document.querySelectorAll('select.tom-select').forEach((el) => {
                    if (definitions[el.id]) {
                        let tomElementAttr = {...tomSelectDefault };
                        tomElementAttr.options = definitions[el.id];
                        if (el.dataset.tomselect) {
                            let newAttrs = JSON.parse(el.dataset.tomselect);
                            for (let key in newAttrs) {
                                tomElementAttr[key] = newAttrs[key];
                            }
                        }
                        // Attach the TomSelect instance to the HTML element so we can retrieve it later
                        el._TomSelect = new window.TomSelect(el, tomElementAttr);
                    }
                });

                // Set the other (non-tom-select) selects
                document.querySelectorAll('select:not(.tom-select),datalist').forEach((el) => {
                    if (definitions[el.id]) {
                        let choices = definitions[el.id];
                        for (let key in choices) {
                            // After adding the id and value, remove them from choices, so that looping any remaining properties goes faster
                            let option = new Option(choices[key].value, choices[key].id);
                            delete choices[key].id;
                            delete choices[key].value;

                            for (let key2 in choices[key]) {
                                if (key2 != 'id' && key2 != 'value') {
                                    option.setAttribute('data-' + key2, choices[key][key2]);
                                }
                            }
                            el.appendChild(option);
                            //el.options.add(option);
                        }
                    }
                });

                // Install the collapse/uncollapse code
                var els = document.querySelectorAll('[data-lb-toggle="collapse"]');
                for (var el of els) {
                    el.addEventListener('click', el => {
                        if (el.target.classList.contains('collapsed')) {
                            el.target.classList.remove('collapsed');
                        } else {
                            el.target.classList.add('collapsed');
                        }
                        let targets = document.querySelectorAll(el.target.dataset.lbTarget);
                        for (var target of targets) {
                            if (target.classList.contains('show')) {
                                target.classList.remove('show');
                            } else {
                                target.classList.add('show');
                            }
                        }
                    });
                }

                // Install the showhide function
                document.querySelectorAll('[data-lb-showhide="true"] input').forEach(e => e.addEventListener('change', e => {
                    window.application.logboek.doShowHide(e.currentTarget);
                }));

                // Install listeners to the generic buttons (edit, save, cancel)
                el = document.querySelector('[data-lb-button="edit"]');
                if (el) el.addEventListener('click', () => { window.application.editInfo() });
                el = document.querySelector('[data-lb-button="save"]');
                if (el) el.addEventListener('click', () => { window.application.saveInfo() });
                el = document.querySelector('[data-lb-button="cancel"]');
                if (el) el.addEventListener('click', () => { window.application.cancelInfo() });

                // Install listeners for the QR-code scanner
                document.querySelectorAll('[data-lb-button="scan"]').forEach(e => {
                    e.addEventListener('click', e => {
                        window.application.qr.scan((value) => {
                            document.querySelector(e.target.dataset.lbTarget).value = value;
                        });
                    })
                });

                // Install listeners for pictures
                document.querySelectorAll('[data-lb-button="picture"]').forEach(el => {
                    el.addEventListener('click', e => {
                        // This button has this property:
                        // data-lb-target: where do we store the blob reference
                        var elTarget = document.querySelector(e.currentTarget.dataset.lbTarget);
                        if (elTarget) {
                            window.application.capturePhoto(e.target.dataset.lbTarget, elTarget.dataset.lbDisplay);
                        } else {
                            console.error('Unable to take a photo because of an error in the form: target input does not exist.');
                            window.application.alert('Unable to take a photo because of an application error.');
                        }
                    });
                });
                document.querySelectorAll('[data-lb-picture-display]').forEach(el => {
                    document.querySelectorAll(el.dataset.lbPictureDisplay).forEach(elDisplay => {
                        if (elDisplay) elDisplay.onclick = el => {
                            if (el.target && el.target.id) {
                                window.application.removePhoto(el.target.id, el.id);
                            }
                        }
                    });
                });

                // Install listeners for attachments
                document.querySelectorAll('[data-lb-button="attachment"]').forEach(el => {
                    el.addEventListener('click', () => {
                        // This button has these properties:
                        // data-lb-source: where do we pickup the selected file
                        // data-lb-target: where do we store the blob reference
                        var elSource = document.querySelector(el.dataset.lbSource);
                        var elTarget = document.querySelector(el.dataset.lbTarget);

                        if (elSource && elTarget) {
                            window.application.attachment.add(elSource, window.application.generateUUID()).then((storageKey) => {
                                let attachmentValues = [];
                                if (elTarget.value) {
                                    attachmentValues = elTarget.value.split(';');
                                }
                                attachmentValues.push(storageKey);
                                elTarget.value = attachmentValues.join(';');

                                // Show the attachments
                                window.application.showAttachments(elTarget);
                            });
                        } else {
                            console.error('Unable to attach a file because of an error in the form: the source and/or target element does not exist.');
                            window.application.alert('Unable to take a attach a file because of an application error.');
                        }
                    });
                });

                // When all is done, resolve
                resolve();
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    unloadForm(skipConfirm = false) {
        // Unload the html, hide the element
        console.log('app.unloadForm(' + skipConfirm + ')');
        let self = this;

        function closeForm() {
            // Unload the form, hide the element
            let el = document.getElementById('container_app');
            el.innerHTML = '';
            el.hidden = true;

            // If we are in a project, go back to home; if we are in a project-subitem, go back to the project
            if (self.record.fk_project) {
                let record = self.logboek.getProject(self.record.fk_project);
                self.loadForm('project', record, 'read');
            } else {
                document.getElementById('container_vue').hidden = false;
            }
        }

        if (skipConfirm) {
            closeForm();
        } else {
            self.confirm('Wil je dit formulier verlaten?', null, () => { closeForm() });
        }
    }

    loadInfo(info) {
        console.log('app.loadInfo(<info>)');
        let self = this;

        // Figure out if the document is in edit or read mode
        var mode = document.querySelector('#container_app[data-lb-mode]').dataset.lbMode;

        // Reset all inputs
        document.querySelectorAll('form[data-lb-name]').forEach(f => {
            f.reset();
        });

        // Set the read-only values (html) and the inputs
        return new Promise(function(resolve, reject) {
            try {
                // Set the field values into the DOM, if any
                if (info) {
                    // The fields values in info.data have preference over those in info
                    for (let set of[info.data, info]) {
                        if (set) {
                            for (let field in set) {
                                let els = document.querySelectorAll('[data-lb-field="' + field + '"]');
                                for (let cnt = 0; cnt < els.length; cnt++) {
                                    els[cnt].innerHTML = set[field];
                                }

                                var el = document.getElementsByName(field)[0];
                                var value = set[field];
                                if (el) {
                                    // Split multiple values, based on ","
                                    if (el.type == 'select' || el.type == 'checkbox') {
                                        // Split values - if there is no "," this results in an array with one value
                                        value = value.split(',');
                                    }

                                    // Set the value, based on the element type
                                    if (el._TomSelect) {
                                        el._TomSelect.setValue(value, true);
                                        console.log('Setting tomselect ' + el.id + ' to ' + value);
                                    } else if (el.type.toLowerCase() == 'radio') {
                                        // Radio, set the single value
                                        let elInput = document.querySelector('input[name="' + el.name + '"][value="' + value + '"]');
                                        if (elInput) {
                                            window.application.logboek.doShowHide(elInput);
                                            elInput.checked = true;
                                        }
                                    } else if (el.type.toLowerCase() == 'checkbox') {
                                        // Checkbox, set (optionally) multiple values
                                        for (let val of value) {
                                            let elInput = document.querySelector('input[name="' + el.name + '"][value="' + val + '"]');
                                            if (elInput) {
                                                window.application.logboek.doShowHide(elInput);
                                                elInput.checked = true;
                                            }
                                        }
                                    } else if (el.dataset && el.dataset.lbClass == 'json') {
                                        // This element is used for lists etc, so put that content as stringified json into the input element
                                        el.value = JSON.stringify(value);
                                    } else {
                                        // Inputs such as textarea or of type text, number, etc
                                        el.value = value;
                                    }
                                } else {
                                    //console.log('Missing element with name ' + i);
                                }
                            }
                        }
                    }
                }

                // Picture inputs have an attribute that dictates where to display the images
                document.querySelectorAll('[data-lb-picture-display]').forEach(e => {
                    self.showImages(e);
                });

                // Attachment inputs have an attribute that dictates where to display the attachment names/links
                document.querySelectorAll('[data-lb-attachment-display]').forEach(e => {
                    self.showAttachments(e);
                    //self.showAttachments(document.querySelector(e.dataset.lbAttachmentDisplay));
                });

                // Scroll to the top, initially
                window.scrollTo(0, 0);

                // Finally, put the inputs in disabled state, if the form is in read mode
                document.forms[0].querySelectorAll('input,textarea,select').forEach(e => e.disabled = (mode == 'read'));

                resolve(info);
            } catch (error) {
                console.error(error);
                reject(info);
            }
        });
    }

    rememberFieldValues(values) {
        // Called before the form is saved to remember the default field values
        console.log('app.rememberFieldValues(<values>)');
        let self = this;

        // Loop through the html elements, check for data-remember=true
        let remember = self.storage.get('app.remember') || {};

        let hasChanged = false;
        for (let id in values) {
            let el = document.getElementById(id);
            if (el) {
                if (el.hasAttribute('data-properties')) {
                    let properties = {};
                    try {
                        properties = JSON.parse(el.getAttribute('data-properties'));
                    } catch (error) {
                        console.error('Could not parse properties of ' + el.id);
                    }

                    if (properties.remember) {
                        remember[id] = values[id];
                        hasChanged = true;
                    }
                }
            }
        }

        if (hasChanged) {
            self.storage.set('app.remember', remember);
        }
    }

    getFields(form, type = 'store') {
        // Returns a set of fields, where type is either:
        // * any: all fields
        // * store: all that are to be stored
        // * show: all that are to be shown in a list
        // * missing: that are required, but lack values
        console.log('app.getFields(<form>,' + type + ')');

        let result = {};
        try {
            let inputSelector = '[data-lb-properties]';
            if (type == 'any') {
                inputSelector = 'input,textarea,select';
            }
            form.querySelectorAll(inputSelector).forEach((el) => {
                let value = '';
                let properties = '';
                if (el.dataset.lbProperties) {
                    properties = JSON.parse(el.dataset.lbProperties);
                }

                // Figure out the element type - if it's a text input, get the text type value
                let node_type = el.nodeName.toLowerCase();
                if (node_type == 'input') {
                    node_type = el.type.toLowerCase();
                }

                // Get the value(s)
                // For radios and checkboxes, it's enough to put the 'data-properties' attribute on one of the elements
                switch (node_type) {
                    case 'select':
                        // This will work for both multi and single selects
                        value = [...document.querySelectorAll('#' + el.id + ' :checked')].map(option => option.value.trim()).join(',')
                        break;
                    case 'checkbox':
                        value = [...document.querySelectorAll('[name="' + el.name + '"]:checked')].map(el => el.value.trim()).join(',');
                        break;
                    case 'radio':
                        var radioEl = document.querySelector('[name="' + el.name + '"]:checked');
                        value = (radioEl) ? radioEl.value.trim() : '';
                        break;
                    default:
                        if (el.dataset && el.dataset.lbClass == 'json') {
                            // This is a structure, so the value in the input is stringified JSON: parse it
                            value = JSON.parse(el.value);
                        } else {
                            value = el.value.trim();
                        }
                }

                // What is it we need to return
                switch (type) {
                    case 'any':
                        result[el.name] = value;
                        break;
                    case 'store':
                        // This is the id/value pairs that we'll use to store
                        if (properties.store) {
                            result[el.name] = value;
                        }
                        break;
                    case 'show':
                        // This takes the field value, combined with the label specified in the properties, as we'll show the list
                        if (properties.show) {
                            if (result[properties.label] && result[properties.label] != '') {
                                value = result[properties.label] + ', ' + value;
                            }
                            result[properties.label] = value;
                        }
                        break;
                    case 'missing':
                        // This will use the field label (value is not important, but by using the properties.label as key it'll occur just once)
                        if (properties.required) {
                            if (value == '') {
                                result[properties.label] = value;
                            }
                        }
                        break;
                    default:
                        console.error('Unknown type: ' + type);
                        break;
                }
            });
        } catch (error) {
            console.error(error);
            return false;
        }
        return result;
    }

    editInfo() {
        console.log('app.editInfo()');
        let self = this;

        var mode = 'add';
        if (self.record.id) {
            mode = 'edit';
        }

        // Turn the form into edit/add mode
        document.forms[0].querySelectorAll('input,textarea,select').forEach(e => e.disabled = false);
        document.querySelector('#container_app[data-lb-mode]').dataset.lbMode = mode;
    }

    cancelInfo() {
        console.log('app.cancelInfo()');
        document.querySelector('#container_app[data-lb-mode]').dataset.lbMode = 'read';
        if (this.record.id) {
            this.loadInfo(this.record);
        } else {
            this.unloadForm(true);
        }
    }

    saveInfo() {
        console.log('app.saveInfo()');
        let self = this;

        try {
            let form = document.getElementById('form_logboek');

            // Are we updating an existing record, or creating a new?
            let id = self.record.id;
            if (!id) {
                id = self.generateUUID();
            }

            let record = {
                archived: null,
                fk_project: document.getElementById('fk_project').value,
                fk_user: self.user.id,
                date: self.GMTToLocal().substring(0, 10),
                id: id,
                type: form.dataset.lbName,
                updated: self.GMTToLocal(),
                data: self.getFields(form),
            };
            let info_show = self.getFields(form, 'show');
            let info_missing = self.getFields(form, 'missing');

            if (record === false || info_show === false || info_missing === false) {
                self.alert('An error in the file structure was discovered. Please contact application support.');
            } else {
                // Attempt to save it
                self.storeInfo(record.type, record, info_missing, info_show);
            }
        } catch (error) {
            console.log(error);
            this.alert('An error occurred while saving: The information has not been saved. Please contact application support.');
        }
    }

    storeInfo(type, record, values_missing, values_show, remember = true, confirm = true) {
        // values contains the name/value pairs that are to be checked and saved
        console.log('app.storeInfo(' + type + ',<values>,<values_missing>,<values_show>,' + remember + ',' + confirm + ')');
        let self = this;

        try {
            // Prepare the record to store
            record.createdAt = self.GMTToLocal();
            record.status = 'local';

            // Remember the values, if specified and all required values have been provided
            if (remember && Object.keys(values_missing).length == 0) {
                self.rememberFieldValues(record.data);
            }

            // Prepare and show the modal only if confirm is true
            if (confirm) {
                // Prepare the modal
                document.getElementById('infoModalLabel').innerText = 'Gegevens opslaan?';
                document.getElementById('infoModalCancel').hidden = false;

                // Show either the list of missing values, or the list of values
                let html = '';
                if (Object.keys(values_missing).length) {
                    for (let key in values_missing) {
                        html += (html ? ', ' : '') + self.escapeHTML(key).toLowerCase();
                    }
                    // Capitalize only the first character of the string
                    html = html[0].toUpperCase() + html.slice(1).toLowerCase();
                    document.getElementById('infoModalDescription').innerHTML = 'De volgende velden zijn verplicht:<br>' + html + '.';

                    // Hide the OK button
                    document.getElementById('infoModalOK').hidden = true;
                } else {
                    for (let key in values_show) {
                        html += '<tr><td>' + self.escapeHTML(key) + '</td><td>' + self.escapeHTML(values_show[key] || '-') + '</td></tr>';
                    }
                    document.getElementById('infoModalDescription').innerHTML = '<table class="table-sm summary">' + html + '</table>';

                    // Show the OK button, with some code attached
                    document.getElementById('infoModalOK').hidden = false;
                    document.getElementById('infoModalOK').onclick = function() {
                        // Save the information, and emit an event that the database content has changed
                        let key = 'info.projects_contents.' + record.fk_project + '.' + record.id;
                        self.storage.set(key, record, true);
                        self.popupInfoClose();
                        self.unloadForm(true);
                    }
                }

                document.getElementById('infoModal')._modal.show();
            } else {
                // We're not showing anything, but saving directly
                let key = 'info.projects_contents.' + record.fk_project + '.' + record.id;
                self.storage.set(key, record, true);
                self.unloadForm(true);
            }
        } catch (error) {
            console.error(error);
        }
    }

    popupInfoClose() {
        console.log('app.popupInfoClose()');

        try {
            document.getElementById('infoModal')._modal.hide();
        } catch (error) {
            console.error(error);
        }
    }

    download(info, progress) {
        console.log('app.download(<info>,<progress>)');
        let self = this;

        // eli = The element for the textual information, e.g. "Downloading 14/47"
        // elp = The progress bar element
        let eli = document.getElementById(info);
        let elp = document.getElementById(progress);

        function update(completed, total) {
            try {
                eli.innerText = 'Een moment, bezig met synchroniseren (' + completed + ' / ' + total + ')...';
                elp.style.width = Math.round(completed / total * 100) + '%';
            } catch (error) {
                console.error(error);
            }
        }

        function progressPromise(promises, tickCallback) {
            function tick(promise) {
                promise.then(function() {
                    progress++;
                    tickCallback(progress, len);
                });
                return promise;
            }

            var len = promises.length;
            var progress = 0;
            return Promise.all(promises.map(tick));
        }

        return new Promise(function(resolve, reject) {
            try {
                eli.innerText = 'Een moment...';
                elp.style.width = "0%";

                self.api.checkToken().then(() => {
                    // Do the initial fetches to decide what to download; these initial three fetches let us know what to download
                    let headers = new Headers({
                        'Authorization': 'Bearer ' + self.api.token
                    });
                    Promise.all([
                        fetch('https://' + self.api.server + self.api.server_path + 'info', { headers: headers }),
                        fetch('https://' + self.api.server + self.api.server_path + 'forms', { headers: headers }),
                    ]).then((responses) => {
                        // Make sure we get a JSON object from each of the responses
                        return Promise.all(responses.map(function(response) {
                            return response.json();
                        }));
                    }).then((data) => {
                        // Make a list of tasks to carry out: get the blob keys and then decide what downloads to add to the task list
                        self.storage.blobs().then((idbKeys) => {
                            let tasks = [];
                            for (let key in data) {
                                for (let key2 in data[key].data.list) {
                                    tasks.push(fetch('https://' + self.api.server + self.api.server_path + data[key].data.type + '/' + data[key].data.list[key2], { headers: headers }));
                                }
                            }

                            // Add a specific task: forms information, projects
                            tasks.push(fetch('https://' + self.api.server + self.api.server_path + 'forms_info', { headers: headers }));
                            tasks.push(fetch('https://' + self.api.server + self.api.server_path + 'projects', { headers: headers }));
                            tasks.push(fetch('https://' + self.api.server + self.api.server_path + 'projects_contents', { headers: headers }));

                            // idbKeys might be necessary for images later
                            console.log('todo: check idbKeys for images: ' + idbKeys);

                            // Now that we have all tasks, carry them out
                            eli.innerText = 'Een momentje, de app is aan het downloaden...';
                            progressPromise(tasks, update).then((responses) => {
                                // Make sure we get a JSON object from each of the responses
                                return Promise.all(responses.map(function(response) {
                                    if (response.headers) {
                                        if (response.headers.get('content-type') == 'application/json')
                                            return response.json().catch(error => {
                                                console.error(error);
                                                return null;
                                            });
                                    }
                                    return response;
                                }));
                            }).then(
                                (results) => {
                                    try {
                                        if (results) {
                                            for (let key in results) {
                                                if (results[key].data) {
                                                    switch (results[key].data.type) {
                                                        case 'info':
                                                            self.storage.set('info.' + results[key].data.name, results[key].data.content);
                                                            break;
                                                        case 'projects':
                                                            for (let cnt in results[key].data.content) {
                                                                let content = results[key].data.content[cnt];
                                                                let storage_key = 'info.project.' + content.id;
                                                                self.storage.set(storage_key, content);
                                                            }
                                                            break;
                                                        case 'projects_contents':
                                                            // console.log(results[key].data.content);
                                                            for (let cnt in results[key].data.content) {
                                                                let content = results[key].data.content[cnt];
                                                                let storage_key = 'info.projects_contents.' + content.fk_project + '.' + content.id;
                                                                self.storage.set(storage_key, content);
                                                            }
                                                            break;
                                                        case 'form':
                                                            self.storage.set('forms.' + results[key].data.name, results[key].data.content);
                                                            break;
                                                        default:
                                                            // Not accounted for, yet
                                                            console.log('Missing handler for ' + results[key].data.type);
                                                            break;
                                                    }
                                                }
                                            }
                                        }
                                        self.storage.set('sync.downloaded', self.GMTToLocal().substring(0, 16));
                                        eli.innerText = 'Klaar...';
                                        elp.style.width = "100%";
                                        resolve();
                                    } catch (error) {
                                        console.error(error);
                                        eli.innerText = 'Er is een fout opgetreden. Probeer het later nogmaals...';
                                        elp.style.width = "0%";
                                        reject();
                                    }
                                }
                            );
                        });
                    }).catch((error) => {
                        console.error(error);
                        eli.innerText = 'Er is een fout opgetreden. Probeer het later nogmaals...';
                        elp.style.width = "0%";
                        reject();
                    });
                }).catch((error) => {
                    console.error(error);
                    eli.innerText = 'Er is een fout opgetreden. Log opnieuw in en probeer het dan nogmaals...';
                    elp.style.width = "0%";
                    reject();
                });

            } catch (error) {
                console.error(error);
                eli.innerText = 'Er is een fout opgetreden. Probeer het later nogmaals...';
                elp.style.width = "0%";
                reject();
            }
        });
    }

    setPostStatus(status = 'local', key = null) {
        // Sets the status of all posts, usually to 'local' so they can be re-uploaded
        console.log('app.setPostStatus(' + status + ',' + key + ')');
        let self = this;

        let posts = self.storage.getAll('created.');
        for (let post in posts) {
            let update = true;
            if (key && posts[post].id !== key) {
                update = false;
            }
            if (update) {
                posts[post].status = status;
                self.storage.set('created.' + posts[post].type + '.' + posts[post].id, posts[post]);
            }
        }
        return true;
    }

    countElementsToUpload() {
        // Counts the number of elements to upload, both those in localStorage and the photos in indexedDB
        console.log('app.countElementsToUpload()');
        let self = this;
        let result = 0;

        // The info in localStorage
        let keys = self.storage.keys();
        for (let k in keys) {
            if (keys[k].substring(0, 8) == 'created.') {
                let post = self.storage.get(keys[k]);
                if (post.status == 'local') {
                    result++;
                }
            }
        }

        // The photos in indexedDB
        let photos = self.storage.get('app.pictures');
        for (let photo in photos) {
            if (photos[photo].status == 'local') {
                result++;
            }
        }

        return result;
    }

    uploadGetAsPromise(key, storagetype = 'localstorage') {
        // This is a promise that picks up either the content of key in localStorage, or of a blob from indexeddb
        // It is called by upload(); because indexeddb works with promises, upload() needs to do a .then()
        return new Promise(function(resolve, reject) {
            if (storagetype == 'localstorage') {
                resolve(self.storage.get(key));
            } else {
                self.storage.getBlob(key).then((result) => {
                    try {
                        // Convert the result to base64
                        resolve(window.btoa(String.fromCharCode(...new Uint8Array(result))));
                    } catch (error) {
                        reject(error);
                    }
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    }

    upload() {
        // This is run automatically on app start, and it will keep on running
        // For every iteration it sees if it needs to upload elements (in the foreground or background) and does so for 1 element
        // The timeout is controlled by:
        // * this.uploadTimerFast: when the upload button is clicked, or there's stuff to upload, we repeat quickly again
        // * this.uploadTimerSlow: when there is nothing to do, we give the cpu a longer break before running this again
        // Note: window.setTimeOut is erratic when the app is not in focus; that is just fine
        console.log('app.upload()');
        let self = this;

        // If we're offline, exit rightaway
        if (!self.isOnline()) {
            return window.setTimeout(() => {
                self.upload()
            }, self.uploadTimerSlow);
        }

        // We'll find a key of a post that hasn't been uploaded yet, either in localstorage or indexeddb
        let key = null;
        let storagetype = null;

        let uploadAuto = self.storage.get('app.uploadAutomatic');
        let uploadManual = self.storage.get('app.uploadManual');
        if (uploadAuto || uploadManual) {
            // The user has autoUpload on, or is looking at the modal
            // So we will want to actually upload content, if possible and necessary, and then continue asap with the next upload()

            // Get any uploadable element
            let keys = self.storage.keys();
            for (let k in keys) {
                if (keys[k].substring(0, 8) == 'created.') {
                    // Inspect the status
                    let post = self.storage.get(keys[k]);
                    if (post.status == 'local') {
                        key = keys[k];
                        storagetype = 'localstorage';
                    }
                }
            }

            // If there are no posts to upload, check for pictures
            // The picture list is kept in localstorage with key "key.pictures"
            if (!key) {
                let pictures = self.storage.get('app.pictures');
                for (let picture in pictures) {
                    if (pictures[picture].status == 'local') {
                        key = pictures[picture].key;
                        storagetype = 'indexeddb';
                    }
                }
            }

            // If there is nothing to upload, set the progress bar as finished
            if (!key) {
                self.storage.set('app.uploadManual', false);
                self.storage.set('sync.uploaded', self.GMTToLocal().substring(0, 16));

                let eli = document.getElementById('upload_inform')
                if (eli) { eli.innerText = 'Alle informatie is naar de server gestuurd.'; }
                let elp = document.getElementById('upload_progress');
                if (elp) { elp.style.width = '100%'; }
            }
        }

        if (key) {
            // Get the content
            self.uploadGetAsPromise(key, storagetype)
                .then((content) => {
                    // Images are base64, which is a string; posts are jsons which are objects
                    let method = 'post_info';
                    if (typeof content == 'string') {
                        method = 'post_image';
                    }

                    // Do the upload, and if all went well, inform, and update the record
                    self.api.doPost(method, key, content).then((result) => {
                        // If the result was successful
                        if (result) {
                            // Update the status so we know when it was last run
                            self.storage.set('sync.uploaded', self.GMTToLocal().substring(0, 16));
                            self.api.upload_done = self.api.upload_done + 1;

                            // Update the status of the post, or if this is a picture, of the entry in app.pictures
                            console.log('Updating status for ' + key);
                            if (storagetype == 'localstorage') {
                                content.status = 'uploaded';
                                self.storage.set(key, content);
                            } else {
                                let currentValues = self.storage.getFrom('app.pictures', key);
                                self.storage.setIn('app.pictures', key, {
                                    key: key,
                                    createdAt: currentValues.createdAt,
                                    status: 'uploaded'
                                });
                            }

                            // Update the progress bar, if available
                            let elp = document.getElementById('upload_progress');
                            if (elp) {
                                // Adjust the width based on the number elements uploaded vs those initially to be done
                                let width = Math.round(self.api.upload_done / self.api.upload_total * 100);
                                if (width > 100) {
                                    width = 100;
                                }
                                elp.style.width = width + '%';
                            } else {
                                // If the progress bar with modal is not available, let the screen be redrawn
                                window.dispatchEvent(new CustomEvent('storage-changed', {
                                    detail: {
                                        action: 'upload',
                                        key: key
                                    }
                                }));
                            }

                            // Call the function again, shortly
                            return window.setTimeout(() => {
                                self.upload()
                            }, self.uploadTimerFast);
                        } else {
                            console.error('An error occurred posting data to the API.');
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                    if (key.substring(0, 7) == 'photos.') {
                        console.log('Updating status for ' + key + ': it was specified in app.pictures but was not found in indexedDB.');
                        let currentValues = self.storage.getFrom('app.pictures', key);
                        self.storage.setIn('app.pictures', key, {
                            key: key,
                            createdAt: currentValues.createdAt,
                            status: 'uploaded'
                        });
                    }

                    // Call the function again, shortly
                    return window.setTimeout(() => {
                        self.upload()
                    }, self.uploadTimerFast);
                });
        } else {
            // This function runs periodically anyways and should continue to do so, but give the cpu a break of a few seconds
            // This is long enough so the CPU isn't bothered too much, yet short enough for the user to wait when clicking upload
            return window.setTimeout(() => {
                self.upload()
            }, self.uploadTimerSlow);
        }
    }

    cleanup(days = 7, localStorage = true, indexedDB = true) {
        // Cleans up already-uploaded elements if they are more than <days> days old
        console.log('app.cleanup(' + days + ',' + localStorage + ',' + indexedDB + ')');
        let cutoffDate = this.GMTToLocal(new Date(Date.now() - days * 24 * 60 * 60 * 1000));

        // Loop through the posts in localStorage
        let cntRemovedPosts = 0;
        if (localStorage) {
            let posts = this.storage.getAll('created.');
            for (let post of posts) {
                if (post.status == 'uploaded') {
                    if (post.createdAt < cutoffDate) {
                        this.storage.remove('created.' + post.type + '.' + post.id);
                        cntRemovedPosts++;
                    }
                }
            }
        }

        // Loop through the blobs in indexedDB (pictures)
        // Value app.pictures is set when taking a picture and updated when uploading
        let cntRemovedBlobs = 0;
        let promises = [];
        if (localStorage) {
            let picture_list = self.storage.get('app.pictures');
            for (let el in picture_list) {
                if (picture_list[el].createdAt < cutoffDate) {
                    promises.push(window.application.storage.removeBlob(picture_list[el].key));
                }
            }
        }

        let eli = document.getElementById('cleanup_inform');
        if (promises.length == 0) {
            if (cntRemovedPosts == 0) {
                eli.innerText = 'Klaar.\nDe applicatie hoefde niets op te ruimen.';
            } else {
                eli.innerText = 'Klaar.\nDe applicatie heeft ' + cntRemovedPosts + ' posts opgeruimd.';
            }
        } else {
            Promise
                .allSettled(promises)
                .then((result) => {
                    // Remove the pictures from the list (deleting them once is enough)
                    let picture_list = self.storage.get('app.pictures');
                    for (let el in picture_list) {
                        if (picture_list[el].createdAt < cutoffDate) {
                            self.storage.removeFrom('app.pictures', picture_list[el].key);
                        }
                    }

                    // Count the results and report
                    for (let result_el of result) {
                        if (result_el) {
                            cntRemovedBlobs++;
                        }
                    }
                    if (eli) {
                        if (cntRemovedBlobs == promises.length) {
                            if (cntRemovedPosts == 0 && cntRemovedBlobs == 0) {
                                eli.innerText = 'Klaar.\nDe applicatie hoefde niets op te ruimen.';
                            } else if (cntRemovedPosts == 0 && cntRemovedBlobs > 0) {
                                eli.innerText = 'Klaar.\nDe applicatie heeft ' + cntRemovedBlobs + ' fotos opgeruimd.';
                            } else if (cntRemovedPosts > 0 && cntRemovedBlobs == 0) {
                                eli.innerText = 'Klaar.\nDe applicatie heeft ' + cntRemovedPosts + ' posts opgeruimd.';
                            } else {
                                eli.innerText = 'Klaar.\nDe app heeft ' + cntRemovedPosts + ' posts en ' + cntRemovedBlobs + ' fotos opgeruimd.';
                            }
                        } else {
                            eli.innerText = 'Er is een fout opgetreden.\n' + cntRemovedBlobs + ' van de ' + promises.length + ' fotos werden opgeruimd ble fjernet. Neem contact op met support.';
                        }
                    }
                });
        }
    }

    capturePhoto(storage = '#picture', display = '#pictures') {
        // Lets the user take a picture, add it to the post
        console.log('app.capturePhoto(' + storage + ',' + display + ')');
        let self = this;

        if (document.querySelector('#container_app[data-lb-mode]').dataset.lbMode !== 'read') {
            self.camera.capturePhoto(self.generateUUID(), (key) => {
                // Add the image to the display container
                let displayElement = document.querySelector(display);
                if (displayElement) {
                    // Create a new image holder
                    let newPicture = '<img class="picture" src="" id="' + key + '">';
                    displayElement.innerHTML += newPicture;

                    // Have the application insert the image to it
                    self.storage.loadBlob(key, key);
                }

                // Add the key to the input
                let storageElement = document.querySelector(storage);
                if (storageElement) {
                    let picValues = [];
                    if (storageElement.value) {
                        picValues = storageElement.value.split(';');
                    }
                    picValues.push(key);
                    storageElement.value = picValues.join(';');
                }
                return key;
            });
        }
    }

    removePhoto(imageId, storage = '#picture') {
        // Lets the user remove a picture
        console.log('app.removePhoto(' + imageId + ',' + storage + ')');
        let self = this;

        if (document.querySelector('#container_app[data-lb-mode]').dataset.lbMode !== 'read') {
            self.confirm('Wil je deze foto verwijderen?', '', () => {
                // Remove it from the database and the DOM
                self.storage.removeBlob(imageId);
                document.getElementById(imageId).remove();

                // Also remove the text from the storage element
                let storageElement = document.querySelector(storage);
                if (storageElement) {
                    let values = storageElement.value.split(';');
                    values = values.filter(arrayItem => arrayItem !== imageId);
                    storageElement.value = values.join(';');
                }
            });
        }
    }

    showImages(containerElement) {
        // The container element is an input and has attribute "data-lb-picture-display" where to display the images
        console.log('app.showImages(<containerElement>)');
        let self = this;

        // Try to get the display container
        let displayElement = document.querySelector(containerElement.dataset.lbPictureDisplay);
        if (displayElement) {
            displayElement.innerHTML = '';
            let contents = containerElement.value.split(';');
            contents.forEach(key => {
                key = key.trim();
                if (key != '') {
                    let newPicture = '<img class="picture" src="" id="' + key + '">';
                    displayElement.innerHTML += newPicture;

                    // Have the application insert the image to it
                    self.storage.loadBlob(key, key);
                }
            });
        } else {
            console.error('Display element "' + containerElement.dataset.lbPictureDisplay + '" was not found.');
        }
    }

    removeAttachment(attachmentId, storage = '#attachment') {
        // Lets the user remove a attachment
        console.log('app.removeAttachment(' + attachmentId + ',' + storage + ')');
        let self = this;

        if (document.querySelector('#container_app[data-lb-mode]').dataset.lbMode !== 'read') {
            self.confirm('Wil je deze bijlage verwijderen?', '', () => {
                // Remove it from the database and the DOM
                self.storage.removeBlob(attachmentId);
                document.getElementById(attachmentId).remove();

                // Also remove the text from the storage element
                let storageElement = document.querySelector(storage);
                if (storageElement) {
                    let values = storageElement.value.split(';');
                    values = values.filter(arrayItem => arrayItem !== attachmentId);
                    storageElement.value = values.join(';');
                }
            });
        }
    }

    showAttachments(containerElement) {
        // The container element is an input and has attribute "data-lb-attachment-display" where to display the attachments
        console.log('app.showAttachments(<containerElement>)');

        // Try to get the display container
        var html = '';
        var displayElement = document.querySelector(containerElement.dataset.lbAttachmentDisplay);
        if (displayElement) {
            let contents = containerElement.value.split(';');
            contents.forEach(key => {
                key = key.trim();
                if (key != '') {
                    // key looks like 'attachments.a56a61ce-fd4e-48c7-8e69-4b565d04a972.docentplus.txt'
                    // so remove the 1st and 2nd part
                    let displayValue = key.split('.').slice(2).join('.');
                    html += '<div id="' + key + '" class="my-2">';
                    html += '<i class="fas fa-trash-alt hide-on-read me-2" onclick="application.removeAttachment(\'' + key + '\')"></i>';
                    html += '<a href="#" target="_blank" onclick="return application.storage.downloadBlob(\'' + key + '\',this)">' + displayValue + '</a>';
                    html += '</div>';
                }
            });
            displayElement.innerHTML = html;
        } else {
            console.error('Display element "' + containerElement.dataset.lbAttachmentDisplay + '" was not found.');
        }
    }
}