import { Modal } from 'bootstrap';
import QrScanner from 'qr-scanner';

export default class QRCode {
    constructor() {}

    initialize(idModal) {
        console.log('qrcode.initialize(' + idModal + ')');
        let self = this;

        self.modal = new Modal(document.getElementById(idModal), {
            backdrop: 'static',
            keyboard: false
        });
    }

    cancel() {
        console.log('qrcode.cancel()');
        let self = this;

        try {
            self.qrscanner.stop();
            self.modal.hide();
        } catch (error) {
            console.error(error);
        }
    }

    scan(callback) {
        console.log('qrcode.scan(<callback>)');
        let self = this;

        let videoElement = document.getElementById('qr-video');
        let options = {
            preferredCamera: 'environment',
            highlightScanRegion: true,
            returnDetailedScanResult: true,
        };

        self.modal.show();
        self.qrscanner = new QrScanner(
            videoElement,
            result => {
                self.cancel();
                callback(result.data)
            },
            options);
        self.qrscanner.start();
    }
}